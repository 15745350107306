import { env, fatal } from '@/utils'
import { SelectedCoinDenom, SelectedCoinMinimalDenom } from '@/wallet-utils/types'

// This file contains meta configuration for each chain supported

interface ChainConfig {
  // The denom of the chain's tokens on Stride
  // This is used to query the balance of the chain's tokens on Stride
  // as well as to identify which denom to send (for 'Withdraw' feature)
  // Leave as blank if the chain info is only used as a DEX.
  // Use https://stridef.kierb.com/ to generate ibcDenom with inputs as:
  // channel: `transfer/${withdrawChannel}`
  // microdenom: microdenom
  ibcDenom: string
  // The denom of the st token on the chain
  // This is used for the 'Send to Chain' feature i.e., components named WithdrawStToken
  // If the feature is not part of the CHAIN_SUPPORTS_WITHDRAW_ST_TOKEN variable,
  // or if the chain info is only used as a DEX, then you can leave this blank.
  // Use https://stridef.kierb.com/ to generate ibcDenom with inputs as:
  // channel: `transfer/${depositChannel}`
  // microdenom: `st${microdenom}`
  stakedIbcDenom: string
  // The ibc denom of the selected chain's natively staked balance on Stride-side.
  // This is used for lsm.
  lsmIbcDenom: string
  // IBC channel from chain to Stride
  depositChannel: string
  // IBC channel from Stride to chain
  // We use this channel to compute the rate-limiting percentage on status page
  withdrawChannel: string
  // 0.1 for now, revisit in the future
  minimumGas: number
  // Url to the chain's assets
  // This is used for the 'Send to Chain' feature i.e., components named WithdrawStToken
  assetsUrl: string
  // Used to properly add propery links for transaction cards to mintscan
  mintscanId: string
  // ID to access the edge server stats
  // e.g. https://edge.stride.zone/api/{edgeServerId}/stats/tvl
  // i.e. https://edge.stride.zone/api/dydx/stats/tvl
  edgeServerId: string
  // When the user does not have sufficient STRD tokens to use as gas, this is the value we deduct
  // from the amount if they may be using the full amount.
  // @TODO: Use a smarter way to do this (option a: simulate then deduct the simulated
  // fee to the amount)
  classicLsGasEstimate: number
  // Minimum amount to allow liquid stake. By default this is set to 0.015, so gas auto-allocation feature is able
  // to cover the gas fee. but stTIA at least needs something higher given it uses a different module.
  // @TODO: This is not entirely specific to classic ls, but the necessity came from the introduction of staketia and stakedym
  // (multi-sig) which both use classic liquid staking. Let's rename probably!
  classicLsMinimum: number
  // Minimum amount to allow unstaking. Introduced by stTIA and stDYM (multi-sig). Try to make sure this is less than the
  // value of classicLsMinimum so most users will be able to unstake what they liquid stake.
  unstakeMinimum: number
}

const warnIncorrectChainConfigAccess = (keyword: string) => {
  return fatal(
    `You are accessing Stride's ${keyword} property. This is highly likely a mistake. Use the ${keyword} property of the other chain.`
  )
}

const CHAIN_CONFIG: Record<SelectedCoinDenom, ChainConfig> = {
  ATOM: {
    ibcDenom: 'ibc/27394FB092D2ECCD56123C74F36E4C1F926001CEADA9CA97EA622B25F41E5EB2',
    stakedIbcDenom: '',
    lsmIbcDenom: 'ibc/457E522AB5A620091319195479CADC0638D42A24B49B420BB728F424E9CA60A1',
    depositChannel: env('GAIA_DEPOSIT_CHANNEL', 'channel-391'),
    withdrawChannel: env('GAIA_WITHDRAW_CHANNEL', 'channel-0'),
    minimumGas: 0.1,
    assetsUrl: '',
    mintscanId: 'cosmos',
    edgeServerId: 'cosmos',
    // We currently don't support auto-allocation for ATOM Liquid Staking
    classicLsGasEstimate: 0,
    // Anything less is trivial and will only cause issues given gas auto-allocation
    classicLsMinimum: 0.015,
    // When users liquid stake the minimum amount, they should be able to unstake it
    unstakeMinimum: 0.01
  },

  STRD: {
    get ibcDenom(): string {
      throw warnIncorrectChainConfigAccess('ibc denom')
    },
    get stakedIbcDenom(): string {
      throw warnIncorrectChainConfigAccess('staked ibc denom')
    },
    get lsmIbcDenom(): string {
      throw warnIncorrectChainConfigAccess('lsm ibc denom')
    },
    get depositChannel(): string {
      throw warnIncorrectChainConfigAccess('deposit channel')
    },
    get withdrawChannel(): string {
      throw warnIncorrectChainConfigAccess('withdraw channel')
    },
    get minimumGas(): number {
      throw warnIncorrectChainConfigAccess('minimum gas')
    },
    get assetsUrl(): string {
      throw warnIncorrectChainConfigAccess('assets url')
    },
    mintscanId: 'stride',
    edgeServerId: 'stride',
    get classicLsGasEstimate(): number {
      throw warnIncorrectChainConfigAccess('classic liquid stake gas estimate')
    },
    get classicLsMinimum(): number {
      throw warnIncorrectChainConfigAccess('classic liquid stake minimum value')
    },
    get unstakeMinimum(): number {
      throw warnIncorrectChainConfigAccess('unstake minimum')
    }
  },

  OSMO: {
    ibcDenom: 'ibc/D24B4564BCD51D3D02D9987D92571EAC5915676A9BD6D9B0C1D0254CB8A5EA34',
    stakedIbcDenom: '',
    lsmIbcDenom: '',
    depositChannel: env('OSMO_DEPOSIT_CHANNEL', 'channel-326'),
    withdrawChannel: env('OSMO_WITHDRAW_CHANNEL', 'channel-5'),
    minimumGas: 0.1,
    assetsUrl: '',
    mintscanId: 'osmosis',
    edgeServerId: 'osmosis',
    // We currently don't support auto-allocation for OSMO Liquid Staking
    classicLsGasEstimate: 0,
    // Anything less is trivial and will only cause issues given gas auto-allocation
    classicLsMinimum: 0.015,
    // When users liquid stake the minimum amount, they should be able to unstake it
    unstakeMinimum: 0.01
  },

  STARS: {
    ibcDenom: 'ibc/7EAE5BEF3A26B64AFBD89828AFDDB1DC7024A0276D22745201632C40E6E634D0',
    stakedIbcDenom: '',
    lsmIbcDenom: '',
    depositChannel: env('STARS_DEPOSIT_CHANNEL', 'channel-106'),
    withdrawChannel: env('STARS_WITHDRAW_CHANNEL', 'channel-19'),
    minimumGas: 0.1,
    assetsUrl: '',
    mintscanId: 'stargaze',
    edgeServerId: 'stargaze',
    // We currently don't support auto-allocation for STARS Liquid Staking
    classicLsGasEstimate: 0,
    // Anything less is trivial and will only cause issues given gas auto-allocation
    classicLsMinimum: 0.015,
    // When users liquid stake the minimum amount, they should be able to unstake it
    unstakeMinimum: 0.01
  },

  SCRT: {
    ibcDenom: 'ibc/27394FB092D2ECCD56123C74F36E4C1F926001CEADA9CA97EA622B25F41E5EB2',
    stakedIbcDenom: '',
    lsmIbcDenom: '',
    depositChannel: env('SCRT_DEPOSIT_CHANNEL', 'channel-0'),
    withdrawChannel: env('SCRT_WITHDRAW_CHANNEL', 'channel-0'),
    minimumGas: 0.1,
    assetsUrl: '',
    mintscanId: '',
    edgeServerId: '',
    classicLsGasEstimate: 0,
    classicLsMinimum: 0,
    unstakeMinimum: 0
  },

  JUNO: {
    ibcDenom: 'ibc/DA356E369C3E5CF6A9F1DCD99CE8ED55FBD595E676A5CF033CE784C060492D5A',
    stakedIbcDenom: '',
    lsmIbcDenom: '',
    depositChannel: env('JUNO_DEPOSIT_CHANNEL', 'channel-139'),
    withdrawChannel: env('JUNO_WITHDRAW_CHANNEL', 'channel-24'),
    minimumGas: 0.1,
    assetsUrl: '',
    mintscanId: 'juno',
    edgeServerId: 'juno',
    // We currently don't support auto-allocation for JUNO Liquid Staking
    classicLsGasEstimate: 0,
    // Anything less is trivial and will only cause issues given gas auto-allocation
    classicLsMinimum: 0.015,
    // When users liquid stake the minimum amount, they should be able to unstake it
    unstakeMinimum: 0.01
  },

  LUNA: {
    ibcDenom: 'ibc/E61BCB1126F42A2ED73B4CEA2221C9635BC2102F0417543C38071779F991942E',
    stakedIbcDenom: 'ibc/08095CEDEA29977C9DD0CE9A48329FDA622C183359D5F90CF04CC4FF80CBE431',
    lsmIbcDenom: '',
    depositChannel: env('LUNA_DEPOSIT_CHANNEL', 'channel-46'),
    withdrawChannel: env('LUNA_WITHDRAW_CHANNEL', 'channel-52'),
    minimumGas: 0.1,
    assetsUrl: 'https://station.terra.money/history',
    mintscanId: 'terra',
    edgeServerId: 'terra2',
    // We currently don't support auto-allocation for LUNA Liquid Staking
    classicLsGasEstimate: 0,
    // Anything less is trivial and will only cause issues given gas auto-allocation
    classicLsMinimum: 0.015,
    // When users liquid stake the minimum amount, they should be able to unstake it
    unstakeMinimum: 0.01
  },

  EVMOS: {
    ibcDenom: 'ibc/4B322204B4F59D770680FE4D7A565DDC3F37BFF035474B717476C66A4F83DD72',
    stakedIbcDenom: 'ibc/C49FDA9CB980D813492E2DFFF255B01C46E629C73B2B468131F6FE6E14F55754',
    lsmIbcDenom: '',
    depositChannel: env('EVMOS_DEPOSIT_CHANNEL', 'channel-25'),
    withdrawChannel: env('EVMOS_WITHDRAW_CHANNEL', 'channel-9'),
    minimumGas: 0.1,
    assetsUrl: 'https://app.evmos.org/assets',
    mintscanId: 'evmos',
    edgeServerId: 'evmos',
    // We currently don't support auto-allocation for EVMOS Liquid Staking
    classicLsGasEstimate: 0,
    // Anything less is trivial and will only cause issues given gas auto-allocation
    classicLsMinimum: 0.015,
    // When users liquid stake the minimum amount, they should be able to unstake it
    unstakeMinimum: 0.01
  },

  INJ: {
    ibcDenom: 'ibc/A7454562FF29FE068F42F9DE4805ABEF54F599D1720B345D6518D9B5C64EA6D2',
    stakedIbcDenom: 'ibc/AC87717EA002B0123B10A05063E69BCA274BA2C44D842AEEB41558D2856DCE93',
    lsmIbcDenom: '',
    depositChannel: env('INJ_DEPOSIT_CHANNEL', 'channel-89'),
    withdrawChannel: env('INJ_WITHDRAW_CHANNEL', 'channel-6'),
    minimumGas: 0.1,
    assetsUrl: 'https://hub.injective.network/',
    mintscanId: 'injective',
    edgeServerId: 'injective',
    // We currently don't support auto-allocation for INJ Liquid Staking
    classicLsGasEstimate: 0,
    // Anything less is trivial and will only cause issues given gas auto-allocation
    classicLsMinimum: 0.015,
    // When users liquid stake the minimum amount, they should be able to unstake it
    unstakeMinimum: 0.01
  },

  UMEE: {
    ibcDenom: 'ibc/1A2271226209D309902AFF4F21BD21237CB514DD24EA2EE0423BF74C6135D8B8',
    stakedIbcDenom: '',
    lsmIbcDenom: '',
    depositChannel: env('UMEE_DEPOSIT_CHANNEL', 'channel-34'),
    withdrawChannel: env('UMEE_WITHDRAW_CHANNEL', 'channel-29'),
    minimumGas: 0.1,
    assetsUrl: 'https://app.umee.cc/#/dashboard/',
    mintscanId: 'umee',
    edgeServerId: 'umee',
    // We currently don't support auto-allocation for UMEE Liquid Staking
    classicLsGasEstimate: 0,
    // Anything less is trivial and will only cause issues given gas auto-allocation
    classicLsMinimum: 0.015,
    // When users liquid stake the minimum amount, they should be able to unstake it
    unstakeMinimum: 0.01
  },

  CRE: {
    // We currently only use Crescent as DEX for Umee.
    ibcDenom: '',
    stakedIbcDenom: '',
    lsmIbcDenom: '',
    depositChannel: env('CRE_DEPOSIT_CHANNEL', 'channel-29'),
    withdrawChannel: env('CRE_WITHDRAW_CHANNEL', 'channel-51'),
    minimumGas: 0.1,
    assetsUrl: 'https://app.crescent.network/portfolio',
    mintscanId: 'crescent',
    edgeServerId: 'crescent',
    // We currently don't support auto-allocation for CRE Liquid Staking
    classicLsGasEstimate: 0,
    // Anything less is trivial and will only cause issues given gas auto-allocation
    classicLsMinimum: 0.015,
    // When users liquid stake the minimum amount, they should be able to unstake it
    unstakeMinimum: 0.01
  },

  CMDX: {
    ibcDenom: 'ibc/EB66980014602E6BD50A1CB9FFB8FA694DC3EC10A48D2C1C649D732954F88D4A',
    stakedIbcDenom: '',
    lsmIbcDenom: '',
    depositChannel: env('CMDX_DEPOSIT_CHANNEL', 'channel-45'),
    withdrawChannel: env('CMDX_WITHDRAW_CHANNEL', 'channel-49'),
    minimumGas: 0.1,
    assetsUrl: '',
    mintscanId: 'comdex',
    edgeServerId: 'comdex',
    // We currently don't support auto-allocation for CMDX Liquid Staking
    classicLsGasEstimate: 0,
    // Anything less is trivial and will only cause issues given gas auto-allocation
    classicLsMinimum: 0.015,
    // When users liquid stake the minimum amount, they should be able to unstake it
    unstakeMinimum: 0.01
  },

  IBCX: {
    ibcDenom: 'factory/osmo1my6xmjwh496qxh3vtsvuk9nmslx7r6h59p7xt0sv82z5ettckfwq97qrr6/uibcx',
    stakedIbcDenom: 'factory/osmo1hxchwkh4gcctpm20jtry7uzxheg9c0tchs4nusxhjpldxf0gqlvsxn850z/ustibcx',
    lsmIbcDenom: '',
    depositChannel: env('OSMO_DEPOSIT_CHANNEL', 'channel-326'),
    withdrawChannel: env('OSMO_WITHDRAW_CHANNEL', 'channel-5'),
    minimumGas: 0.1,
    assetsUrl: '',
    // @TODO: Fix this once IBCX is available on mintscan + we bring back IBCX
    get mintscanId(): string {
      throw new Error('Mintscan ID for IBCX is not available yet.')
    },
    get edgeServerId(): string {
      throw new Error('Edge Server ID for IBCX is not available yet.')
    },
    classicLsGasEstimate: 0,
    classicLsMinimum: 0.001,
    unstakeMinimum: 0.001
  },

  KUJI: {
    // We currently only use KUJI for Defi Integrations, but it has no implementation yet.
    ibcDenom: '',
    stakedIbcDenom: '',
    lsmIbcDenom: '',
    depositChannel: '',
    withdrawChannel: '',
    minimumGas: 0.1,
    assetsUrl: '',
    mintscanId: '',
    edgeServerId: '',
    classicLsGasEstimate: 0,
    classicLsMinimum: 0.001,
    unstakeMinimum: 0.001
  },

  USK: {
    // We currently only use USK for Defi Integrations, but it has no implementation yet.
    ibcDenom: '',
    stakedIbcDenom: '',
    lsmIbcDenom: '',
    depositChannel: '',
    withdrawChannel: '',
    minimumGas: 0.1,
    assetsUrl: '',
    mintscanId: '',
    edgeServerId: '',
    classicLsGasEstimate: 0,
    classicLsMinimum: 0,
    unstakeMinimum: 0
  },

  IST: {
    // We currently only use IST for Defi Integrations, but it has no implementation yet.
    ibcDenom: '',
    stakedIbcDenom: '',
    lsmIbcDenom: '',
    depositChannel: '',
    withdrawChannel: '',
    minimumGas: 0.1,
    assetsUrl: '',
    mintscanId: '',
    edgeServerId: '',
    classicLsGasEstimate: 0,
    classicLsMinimum: 0,
    unstakeMinimum: 0
  },

  SWTH: {
    // We currently only use SWTH for Defi Integrations, but it has no implementation yet.
    ibcDenom: '',
    stakedIbcDenom: '',
    lsmIbcDenom: '',
    depositChannel: '',
    withdrawChannel: '',
    minimumGas: 0.1,
    assetsUrl: '',
    mintscanId: '',
    edgeServerId: '',
    classicLsGasEstimate: 0,
    classicLsMinimum: 0,
    unstakeMinimum: 0
  },

  USC: {
    // We currently only use USC for Defi Integrations, but it has no implementation yet.
    ibcDenom: '',
    stakedIbcDenom: '',
    lsmIbcDenom: '',
    depositChannel: '',
    withdrawChannel: '',
    minimumGas: 0.1,
    assetsUrl: '',
    mintscanId: '',
    edgeServerId: '',
    classicLsGasEstimate: 0,
    classicLsMinimum: 0,
    unstakeMinimum: 0
  },

  BLD: {
    // We currently only use BLD for Defi Integrations, but it has no implementation yet.
    // Also only used in stTIA Airdrop & stDYM Airdrop
    ibcDenom: '',
    stakedIbcDenom: '',
    lsmIbcDenom: '',
    depositChannel: '',
    withdrawChannel: '',
    minimumGas: 0.1,
    assetsUrl: '',
    mintscanId: '',
    edgeServerId: '',
    classicLsGasEstimate: 0,
    classicLsMinimum: 0,
    unstakeMinimum: 0
  },

  SOMM: {
    ibcDenom: 'ibc/B86EFF6D227E3E33D7E3B5E65D0C1BB5BD79CCB56D35A9D824F0DD5D52CA43BA',
    stakedIbcDenom: '',
    lsmIbcDenom: '',
    depositChannel: env('SOMM_DEPOSIT_CHANNEL', 'channel-71'),
    withdrawChannel: env('SOMM_WITHDRAW_CHANNEL', 'channel-150'),
    minimumGas: 0.1,
    assetsUrl: '',
    mintscanId: 'sommelier',
    edgeServerId: 'sommelier',
    // We currently don't support auto-allocation for SOMM Liquid Staking
    classicLsGasEstimate: 0,
    // Anything less is trivial and will only cause issues given gas auto-allocation
    classicLsMinimum: 0.015,
    // When users liquid stake the minimum amount, they should be able to unstake it
    unstakeMinimum: 0.01
  },

  DYDX: {
    ibcDenom: 'ibc/561C70B20188A047BFDE6F9946BDDC5D8AC172B9BE04FF868DFABF819E5A9CCE',
    stakedIbcDenom: 'ibc/15781DDDCD499274C978F31A0DBD6DA93D8EE0F7200891898549AFC7C5B5948A',
    lsmIbcDenom: '',
    depositChannel: env('DYDX_DEPOSIT_CHANNEL', 'channel-1'),
    withdrawChannel: env('DYDX_WITHDRAW_CHANNEL', 'channel-160'),
    minimumGas: 0.1,
    assetsUrl: '',
    mintscanId: 'dydx',
    edgeServerId: 'dydx',
    // We currently don't support auto-allocation for DYDX Liquid Staking
    classicLsGasEstimate: 0,
    // Anything less is trivial and will only cause issues given gas auto-allocation
    classicLsMinimum: 0.015,
    // When users liquid stake the minimum amount, they should be able to unstake it
    unstakeMinimum: 0.01
  },

  TIA: {
    ibcDenom: 'ibc/BF3B4F53F3694B66E13C23107C84B6485BD2B96296BB7EC680EA77BBA75B4801',
    stakedIbcDenom: '',
    lsmIbcDenom: '',
    depositChannel: env('TIA_DEPOSIT_CHANNEL', 'channel-4'),
    withdrawChannel: env('TIA_WITHDRAW_CHANNEL', 'channel-162'),
    minimumGas: 0.1,
    assetsUrl: '',
    mintscanId: 'celestia',
    edgeServerId: 'celestia',
    classicLsGasEstimate: 0.02,
    // The minimum amount in the backend is 0.1. Add this to the gas estimate which is 0.02 (total of 0.12)
    // To allow for breathing room and for cleanliness, we'll round off to 0.15
    classicLsMinimum: 0.15,
    // The minimum amount in the backend is 0.1
    unstakeMinimum: 0.1
  },

  DYM: {
    ibcDenom: 'ibc/E1C22332C083574F3418481359733BA8887D171E76C80AD9237422AEABB66018',
    stakedIbcDenom: 'ibc/AB30D2F9C131AE1EFA8449C74490326BD5ACC40EF69A2D8563994AA05CE0B258',
    lsmIbcDenom: '',
    depositChannel: env('DYM_DEPOSIT_CHANNEL', 'channel-0'),
    withdrawChannel: env('DYM_WITHDRAW_CHANNEL', 'channel-197'),
    minimumGas: 0.1,
    assetsUrl: 'https://dym.fyi/',
    mintscanId: 'dymension',
    edgeServerId: '',
    classicLsGasEstimate: 0.02,
    // The minimum amount in the backend is 0.1. Add this to the gas estimate which is 0.02 (total of 0.12)
    // To allow for breathing room and for cleanliness, we'll round off to 0.15
    // @TODO: Confirm in the testnet/with the team
    classicLsMinimum: 0.15,
    // The minimum amount in the backend is 0.1
    unstakeMinimum: 0.1
  },

  SAGA: {
    ibcDenom: 'ibc/520D9C4509027DE66C737A1D6A6021915A3071E30DBA8F758B46532B060D7AA5',
    stakedIbcDenom: 'ibc/487CDAE6E9FF09AB722DE08F65D367CE287E13141E448CEDABDDD60ED5A0A77B',
    lsmIbcDenom: '',
    depositChannel: env('SAGA_DEPOSIT_CHANNEL', 'channel-0'),
    withdrawChannel: env('SAGA_WITHDRAW_CHANNEL', 'channel-213'),
    minimumGas: 0.1,
    assetsUrl: '',
    mintscanId: 'saga',
    edgeServerId: '',
    classicLsGasEstimate: 0.02,
    // Anything less is trivial and will only cause issues given gas auto-allocation
    classicLsMinimum: 0.015,
    // When users liquid stake the minimum amount, they should be able to unstake it
    unstakeMinimum: 0.01
  },

  ISLM: {
    ibcDenom: 'ibc/255BEB856BFBC1B75A3C349CF769E9FADEB595804F4FC688A72D651576B9180E',
    stakedIbcDenom: 'ibc/B1A035A8CC1CCE2D612CCC2F3CB72B6C9DD951670E81A94AEA0A452995EA9C8C',
    lsmIbcDenom: '',
    depositChannel: env('ISLM_DEPOSIT_CHANNEL', 'channel-7'),
    withdrawChannel: env('ISLM_WITHDRAW_CHANNEL', 'channel-240'),
    minimumGas: 0.1,
    assetsUrl: '',
    mintscanId: '',
    edgeServerId: '',
    // We currently don't support ISLM as gas token
    classicLsGasEstimate: 0,
    // Anything less is trivial and will only cause issues given gas auto-allocation
    classicLsMinimum: 0.015,
    // When users liquid stake the minimum amount, they should be able to unstake it
    unstakeMinimum: 0.01
  },

  BAND: {
    ibcDenom: 'ibc/AC11D57A5FBC0DF322615027DB86FAA602283F1801ED71FDDAA26117C41256D7',
    stakedIbcDenom: 'ibc/663C59AFF42D542E81E715CDBA22A9C24BACC8F8CE98783CDFDDA674F4AF5EE5',
    lsmIbcDenom: '',
    depositChannel: env('BAND_DEPOSIT_CHANNEL', 'channel-161'),
    withdrawChannel: env('BAND_WITHDRAW_CHANNEL', 'channel-258'),
    minimumGas: 0.1,
    assetsUrl: '',
    mintscanId: 'band',
    edgeServerId: '',
    // We currently don't support auto-allocation for BAND Liquid Staking
    classicLsGasEstimate: 0,
    // Anything less is trivial and will only cause issues given gas auto-allocation
    classicLsMinimum: 0.015,
    // When users liquid stake the minimum amount, they should be able to unstake it
    unstakeMinimum: 0.01
  }
}

const READABLE_DENOMS: Record<SelectedCoinMinimalDenom, SelectedCoinDenom> = {
  uatom: 'ATOM',
  uosmo: 'OSMO',
  ustars: 'STARS',
  uscrt: 'SCRT',
  ustrd: 'STRD',
  ujuno: 'JUNO',
  uluna: 'LUNA',
  aevmos: 'EVMOS',
  inj: 'INJ',
  uumee: 'UMEE',
  ucre: 'CRE',
  ucmdx: 'CMDX',
  uibcx: 'IBCX',
  ukuji: 'KUJI',
  swth: 'SWTH',
  usomm: 'SOMM',
  ubld: 'BLD',
  adydx: 'DYDX',
  utia: 'TIA',
  adym: 'DYM',
  usaga: 'SAGA',
  aISLM: 'ISLM',
  uband: 'BAND'
}

// @TODO: We should be consistent as the other flags and whitelist. This may mean adding
// a subtype of "SelectedCoinDenom" called "StakeSupportedCoinDenom" equivalent to `SUPPORTED_COIN_DENOMS`
// in the `chain-info.ts` file
export const CHAIN_SUPPORTS_AUTOPILOT_LS: Partial<Record<SelectedCoinDenom, boolean>> = {
  ATOM: true,
  OSMO: true,
  STARS: true,
  JUNO: true,
  LUNA: true,
  EVMOS: true,
  // There is a problem with the Injective only specifically.
  // After using Autopilot, the 'Add to DEX' feature fails because
  // it is not able to fetch the actual st tokens that the wallet
  // received from liquid staking. More over `useLiquidStakeTransactionAmountQuery`
  INJ: false,
  UMEE: true,
  CMDX: true,
  SOMM: true,
  DYDX: true,
  // Multi-sig: No Autopilot support
  TIA: false,
  // Multi-sig: No Autopilot support
  DYM: false,
  SAGA: true,
  ISLM: true,
  BAND: true
}

// @TODO: We should be consistent as the other flags and whitelist. This may mean adding
// a subtype of "SelectedCoinDenom" called "StakeSupportedCoinDenom" equivalent to `SUPPORTED_COIN_DENOMS`
// in the `chain-info.ts` file
//
// 'Pool Nudge' only actually works if the user has the correct dex configuration set
export const CHAIN_SUPPORTS_POOL_NUDGE: Partial<Record<SelectedCoinDenom, boolean>> = {
  ATOM: true,
  OSMO: true,
  STARS: true,
  JUNO: true,
  LUNA: true,
  EVMOS: true,
  INJ: true,
  // Umee currently only supports market rate and does not support 'Pool Nudge' feature
  UMEE: false,
  // Comdex is sunsetting cSwap and we're not replacing it with anything
  // https://x.com/ComdexOfficial/status/1805940026359914509
  CMDX: false,
  SOMM: true,
  // DYDX does not have any specified DEX yet
  DYDX: false,
  // TIA does not have any specified DEX yet
  TIA: false,
  DYM: true,
  SAGA: true,
  ISLM: true,
  BAND: false // No DEX configuration is specified
}

// @TODO: We should be consistent as the other flags and whitelist. This may mean adding
// a subtype of "SelectedCoinDenom" called "StakeSupportedCoinDenom" equivalent to `SUPPORTED_COIN_DENOMS`
// in the `chain-info.ts` file
//
// 'Add to DEX' only actually works if the user has the correct dex configuration set
export const CHAIN_SUPPORTS_ADD_TO_DEX: Partial<Record<SelectedCoinDenom, boolean>> = {
  ATOM: true,
  OSMO: true,
  STARS: true,
  JUNO: true,
  LUNA: true,
  EVMOS: true,
  INJ: true,
  // Umee currently only supports market rate and does not support 'Add to DEX' feature
  UMEE: false,
  // Comdex is sunsetting cSwap and we're not replacing it with anything
  // https://x.com/ComdexOfficial/status/1805940026359914509
  CMDX: false,
  SOMM: true,
  // DYDX does not have any specified DEX yet
  DYDX: false,
  // No DEX configuration is specified
  TIA: false,
  DYM: true,
  SAGA: true,
  ISLM: true,
  BAND: false // No DEX configuration is specified
}

// @TODO: We should be consistent as the other flags and whitelist. This may mean adding
// a subtype of "SelectedCoinDenom" called "StakeSupportedCoinDenom" equivalent to `SUPPORTED_COIN_DENOMS`
// in the `chain-info.ts` file
//
// After adding the chain here, make sure to apply the right value to the chain's `stakedIbcDenom` property as well.
export const CHAIN_SUPPORTS_WITHDRAW_ST_TOKEN: Partial<Record<SelectedCoinDenom, boolean>> = {
  LUNA: true,
  EVMOS: true,
  INJ: true,
  DYDX: true,
  TIA: true,
  DYM: true,
  ISLM: true,
  BAND: true
}

// @TODO: We should be consistent as the other flags and whitelist. This may mean adding
// a subtype of "SelectedCoinDenom" called "StakeSupportedCoinDenom" equivalent to `SUPPORTED_COIN_DENOMS`
// in the `chain-info.ts` file
//
// @TODO: In the future, consider adding all denoms and set to false by default.
// Make sure to add context too.
export const CHAIN_SUPPORTS_LSM: Partial<Record<SelectedCoinDenom, boolean>> = {
  ATOM: true
}

// @TODO: We should be consistent as the other flags and whitelist. This may mean adding
// a subtype of "SelectedCoinDenom" called "StakeSupportedCoinDenom" equivalent to `SUPPORTED_COIN_DENOMS`
// in the `chain-info.ts` file
//
// Allows us to disable unstaking per-chain if needed
export const CHAIN_SUPPORTS_UNSTAKING: Partial<Record<SelectedCoinDenom, boolean>> = {
  ATOM: true,
  OSMO: true,
  STARS: true,
  JUNO: true,
  LUNA: true,
  EVMOS: true,
  INJ: true,
  UMEE: true,
  CMDX: true,
  SOMM: true,
  DYDX: true,
  TIA: true,
  DYM: true,
  SAGA: true,
  ISLM: true,
  BAND: false // @TODO: set to true after 72 hours after launch
}

// If we want the TVL stat below Liquid Stake form to show up.
// Often we want to disable them for new chains because they have no stat yet.
//
// @TODO: On the Stake Stats UI, we have a bug when TVL is disabled causing the
// accordion UI to have a border on the bottom. This is something we need to fix
// in the CollapsibleStatsList.
//
// @TODO: We should be consistent as the other flags and whitelist. This may mean adding
// a subtype of "SelectedCoinDenom" called "StakeSupportedCoinDenom" equivalent to `SUPPORTED_COIN_DENOMS`
// in the `chain-info.ts` file
export const CHAIN_SUPPORTS_TVL_STAT: Partial<Record<SelectedCoinDenom, boolean>> = {
  ATOM: true,
  OSMO: true,
  STARS: true,
  JUNO: true,
  LUNA: true,
  EVMOS: true,
  INJ: true,
  UMEE: true,
  CMDX: true,
  SOMM: true,
  DYDX: true,
  TIA: true,
  DYM: true,
  SAGA: false, // @TODO: ask riley if we want to enable the TVL for SAGA
  ISLM: true,
  BAND: false
}

// It means that the chain is not being used for anything other than as identifier for DEX
// to display its DEX price. Often the RPCs/LCDs for these particular chains do not work.
// @TODO: We should be consistent as the other flags and whitelist. This may mean adding
// a subtype of "SelectedCoinDenom" called "StakeSupportedCoinDenom" equivalent to `SUPPORTED_COIN_DENOMS`
// in the `chain-info.ts` file
//
// @TODO: Primarily used for `useDefiIntegrationsTokensQuery`, we might want to rename.
// Try something like `CHAIN_SUPPORTS_DEFI_INTEGRATION`, or maybe consider just using the
// correct rpcs and rest endpoints.
export const CHAIN_IS_DEX_PLACEHOLDER: Partial<Record<SelectedCoinDenom, boolean>> = {
  // We use SCRT for Shade
  SCRT: false,
  // IBCX support is removed for now, and we don't want `useDefiIntegrationsTokensQuery`
  // to include requesting for this chain.
  IBCX: true,
  // Currently only used as DEX for Umee
  CRE: true
}

// v50 introduces visible breaking changes to IBC so far. And so far, v50 has provided better
// options - and this means often we can use a simple version check to replace deprecated features
// or properties with the new ones.
export const CHAIN_IS_COSMOS_SDK_v50: Partial<Record<SelectedCoinDenom, boolean>> = {
  ATOM: true,
  OSMO: true,
  STARS: false,
  JUNO: false,
  LUNA: false,
  EVMOS: false,
  INJ: false,
  UMEE: false,
  CMDX: false,
  SOMM: false,
  DYDX: true,
  TIA: false,
  DYM: false,
  SAGA: false,
  ISLM: false,
  // sdk version is: "cosmos_sdk_version": "0.45.16".
  // source: https://github.com/cosmos/chain-registry/blob/master/bandchain/chain.json
  BAND: false
}

// Gas Auto Allocation is the feature in which a fraction of the total amount is set aside for gas.
// This is currently only supported for Withdraw, Withdraw St Token, and Add to DEX.
// For instance, if user withdraws 0.15 SAGA, and the gas costs 0.001 SAGA, we'll only send 0.014 SAGA
// (in practice we deduct more since gas is volatile).
//
// Most chains should enable this, unless their tokens are not registered fee tokens on both chain
// and Stride side. In this case, we might want to tell users they need any fee tokens on Stride,
// like STRD.
export const CHAIN_SUPPORTS_GAS_AUTO_ALLOCATION: Partial<Record<SelectedCoinDenom, boolean>> = {
  ATOM: true,
  OSMO: true,
  STARS: true,
  JUNO: true,
  LUNA: true,
  EVMOS: true,
  INJ: true,
  UMEE: true,
  CMDX: true,
  SOMM: true,
  DYDX: true,
  TIA: true,
  DYM: true,
  SAGA: true,
  // ISLM is not a registered fee token on Stride
  ISLM: false,
  BAND: false // Enable once stBAND is a viable fee token after the chain upgrade late August
}

// @TODO: We should be consistent as the other flags and whitelist. This may mean adding
// a subtype of "SelectedCoinDenom" called "StakeSupportedCoinDenom" equivalent to `SUPPORTED_COIN_DENOMS`
// in the `chain-info.ts` file
//
// After adding the chain here, make sure to apply the right value to the chain's `stakedIbcDenom` property as well.

export const CHAIN_SUPPORTS_REFERRAL: Partial<Record<SelectedCoinDenom, boolean>> = {
  ATOM: true,
  OSMO: false,
  STARS: false,
  JUNO: false,
  LUNA: false,
  EVMOS: false,
  INJ: false,
  UMEE: false,
  CMDX: false,
  SOMM: false,
  DYDX: true,
  TIA: true,
  DYM: false,
  SAGA: false,
  // ISLM is not a registered fee token on Stride
  ISLM: false,
  BAND: false
}

export const CHAIN_SUPPORTS_NEW_PROMOTION: Partial<Record<SelectedCoinDenom, boolean>> = {
  ATOM: false,
  OSMO: false,
  STARS: false,
  JUNO: false,
  LUNA: false,
  EVMOS: false,
  INJ: false,
  UMEE: false,
  CMDX: false,
  SOMM: false,
  DYDX: false,
  TIA: false,
  DYM: false,
  SAGA: false,
  ISLM: true,
  BAND: true
}

// List of addresses that we cannot tokenize with, and requires a redelegation
// TL;DR: Requires redelegation
const LSM_VALIDATOR_ADDRESSES_AUTO_REDELEGATE = [
  // Address which has some possible issues with the sdk.
  // Therefore, needs to initiate the redelegate flow regardless of capacity.
  'cosmosvaloper140l6y2gp3gxvay6qtn70re7z2s0gn57zfd832j'
]

/// When STRD on Stride is used as gas, we can safely STRD is used as gas if there's at least 0.01 STRD
// @TODO: Rename to something like `MULTI_SIG_LS_STRD_GAS_ESTIMATE`
const TIA_LIQUID_STAKE_STRD_GAS_ESTIMATE = 0.01

/// When STRD on Stride is used as gas, we can safely STRD is used as gas if there's at least 0.01 STRD
// However we'll nudge to 0.1 for safety, given the user may engage in multiple transactions (Add to DEX,
// Withdraw, Withdraw St Token).
//
// This is used also to determine if the user has enough STRD to use as gas for liquid staking if auto-allocation
// is disabled for their chain.
const STRD_FEE_TOKEN_MINIMUM = 0.1

// At least for most chains we've tested, gas for Withdraw does not exceed 1000 microdenoms (0.001),
// and gas for Withdraw St Token does not exceed 14000 microdenoms (0.014). There is no value in allowing users to
// withdraw amount in which they won't have enough gas to cover eitherways. Let's revisit this if the average gas fee stops working.
// We'll just nudge to 0.1 since we consider anything less to be trivial and a nuisance on top of gas fees issues. Just not worth it,
// unless more people ask for it.
//
// To add, this high value makes it so [users that Liquid Stake minimum value (0.015)] won't be able to withdraw. My current
// assumption is [normal users] would liquid stake more than this. If we're wrong, let's adjust.
const WITHDRAW_MINIMUM_AMOUNT = 0.1

export {
  CHAIN_CONFIG,
  LSM_VALIDATOR_ADDRESSES_AUTO_REDELEGATE,
  READABLE_DENOMS,
  TIA_LIQUID_STAKE_STRD_GAS_ESTIMATE,
  STRD_FEE_TOKEN_MINIMUM,
  WITHDRAW_MINIMUM_AMOUNT
}
